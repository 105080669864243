<template>
  <div>
    <loading v-if="!clientForm"></loading>
    <div class="container" v-else>
      <div class="row">
        <div class="col"></div>
        <div class="col-auto ms-auto">
          <button
            class="btn btn-sm btn-outline-success"
            @click="$router.push('/dashboard')"
          >
            <i class="far fa-times"></i>
          </button>
        </div>
      </div>

      <div v-if="clientForm">
        <div class="row">
          <div class="col my-auto">
            <h5>{{ clientForm.title }}</h5>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p class="alert alert-primary mb-3 mt-1">
              Please complete the following form sections. Your changes are
              saved automatically, so you can pause and come back at any time.
            </p>
          </div>
          <div class="col-auto ms-auto my-auto">
            <button
              class="btn btn-sm btn-outline-success"
              @click="saveProgress"
            >
              <i class="far fa-save me-2"></i>Save Progress
            </button>
          </div>
          <div class="col-auto my-auto">
            <button
              class="btn btn-success btn-lg"
              @click="sendResponse"
              :disabled="busySending"
            >
              <i class="fad fa-share mr-1" v-if="!busySending"></i>
              <i class="fad fa-spinner fa-spin mr-1" v-else></i>
              Submit & Complete
            </button>
          </div>
        </div>

        <div class="row mb-2" v-if="clientForm.status === 'response_received'">
          <div class="col text-center">
            Response Sent:
            {{ this.clientForm.client_response_date | formatDate }}
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <div class="accordion" id="sectionsAccordion">
              <div
                v-for="section in clientForm.sections"
                :key="section.id"
                class="shadow"
              >
                <div class="card sticky-top fixed-top shadow-none">
                  <div
                    class="card-header pl-1 pr-3"
                    :id="'heading' + section.id"
                  >
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link font-weight-bold w-75 text-left"
                        type="button"
                        data-toggle="collapse"
                        :data-target="'#collapse' + section.id"
                        aria-expanded="false"
                        :aria-controls="'collapse' + section.id"
                        @click="scrollTo('collapse' + section.id)"
                      >
                        <i class="fad fa-caret-down mr-2"></i>
                        {{ section.title }}
                      </button>
                      <span class="small float-right">
                        <small>({{ getNumberOfQs(section) }})</small>
                      </span>
                    </h2>
                  </div>
                </div>
                <div class="card">
                  <div
                    :id="'collapse' + section.id"
                    class="collapse"
                    :aria-labelledby="'heading' + section.id"
                    data-parent="#sectionsAccordion"
                  >
                    <div class="card-body" :ref="'body-' + section.id">
                      <form
                        :id="'form-render-' + section.id"
                        @change="saveResponse(section)"
                        @submit.prevent="saveResponse(section)"
                      ></form>
                      <div v-if="section.scored">
                        <h5 class="font-weight-bold">
                          Section Score Total: {{ section.score }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-auto ms-auto my-auto">
            <button
              class="btn btn-sm btn-outline-success"
              @click="saveProgress"
            >
              <i class="far fa-save me-2"></i>Save Progress
            </button>
          </div>
          <div class="col-auto my-auto">
            <button
              class="btn btn-success btn-lg"
              @click="sendResponse"
              :disabled="busySending"
            >
              <i class="fad fa-share mr-1" v-if="!busySending"></i>
              <i class="fad fa-spinner fa-spin mr-1" v-else></i>
              Submit & Complete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

require("jquery-ui-bundle");
require("formBuilder/dist/form-render.min.js");
export default {
  data() {
    return {
      busySending: false,
      clientForm: null,
      sectionRenderers: [],
      unsavedChanges: false,
    };
  },
  computed: {
    client() {
      return this.$store.user;
    },
  },
  methods: {
    fetchClientForm() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/c/forms/" + this.$route.params.id)
        .then(({ data }) => {
          this.clientForm = data;

          for (let i = this.clientForm.sections.length - 1; i >= 0; i--) {
            const section = this.clientForm.sections[i];

            setTimeout(() => {
              var formRenderOptions = {
                dataType: "json",
                formData: section.client_response,
              };

              this.sectionRenderers.push({
                section_id: section.id,
                renderer: $("#form-render-" + section.id).formRender(
                  formRenderOptions
                ),
              });
            }, 500);
          }
        });
    },
    saveProgress() {
      this.clientForm.sections.forEach((s) => this.saveResponse(s));
    },
    saveResponse(section) {
      this.unsavedChanges = true;
      if (this.clientForm.status !== "response_received") {
        const renderer = this.sectionRenderers.filter((renderer) => {
          return renderer.section_id === section.id;
        })[0].renderer;

        if (section.scored) {
          var score = 0;
          renderer.userData.forEach((field) => {
            if (field.type === "number") {
              if (field.userData && parseInt(field.userData[0])) {
                score = score + parseInt(field.userData[0]);
              }
            }
            if (field.type === "radio-group") {
              if (field.userData && parseInt(field.userData[0])) {
                score = score + parseInt(field.userData[0]);
              }
            }
          });
          section.score = score;
        }

        this.$axios
          .post(process.env.VUE_APP_API_URL + "/c/forms/save-response", {
            client_form_section_id: section.id,
            form_data: renderer.userData,
            score: section.score,
          })
          .then(({ data }) => {
            if (this.clientForm.status === "sent") {
              this.clientForm.status = "in_progress";
            }
            this.$EventBus.$emit("alert", data);
            this.unsavedChanges = false;
          })
          .catch((error) => {
            this.unsavedChanges = true;
            this.$EventBus.$emit("alert", {
              color: "danger",
              message: "Error. Unable to save changes.",
            });
          });
      } else {
        this.$EventBus.$emit("alert", {
          color: "danger",
          message: "Response already sent. Unable to save changes.",
        });
      }
    },
    sendResponse() {
      // Clients are now required to fill out a medical reason
      // for their stay, with management requesting that we..
      // don't make it too easy to skip this part of the form.

      // Sadly, due to us saving the forms per section we can't
      // use formBuilder's default functionality for this, so we
      // check the fields filled out status manually here.
      const elements = document.querySelectorAll(".medical-reasons");
      const otherVal = document.querySelector(".other-val");

      let hasValue = !elements.length ? true : false;

      elements.forEach((element) => {
        if (
          (element.classList.contains("medical-reasons") && element.checked)
          &&
          (!element.classList.contains("other-option") || otherVal.value)) {

          hasValue = true;
        }
      });

      if (!hasValue) {
        alert(`Please indicate the medical reason for your visit and/or select "Other" for another reason or not applicable.`)
        return;
      }

      // Default submit flow

      var confirmed = confirm(
        "Your response will be sent to Amchara now. You will not be able to modify your response after submission. Do you wish to continue?"
      );
      if (confirmed) {
        this.busySending = true;
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/c/forms/send-response", {
            client_form_id: this.clientForm.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.clientForm.status = "response_received";
            this.clientForm.client_response_date = moment.now();
            const vm = this;
            setTimeout(() => {
              vm.$router.push("/dashboard");
            }, 500);
            this.busySending = false;
          });
      }
    },
    getNumberOfQs(section) {
      var count = 0;

      const options = JSON.parse(section.form_options);
      if (options) {
        for (let i = 0; i < options.length; i++) {
          if (options[i].type !== "header" && options[i].type !== "paragraph") {
            count++;
          }
        }
      }
      return count;
    },
    scrollTo(elementId) {
      let vm = this;
      let container;
      setTimeout(() => {
        container = vm.$el.querySelector("#" + elementId);
        if (container) {
          scrollTo(0, 0);
          setTimeout(() => {
            //container.scrollIntoView();
            const y = container.getBoundingClientRect().top - 50;
            scrollTo({ top: y, behavior: "smooth" });
          }, 50);
        }
      }, 300);
    },
  },
  mounted() {
    this.fetchClientForm();

    window.addEventListener("beforeunload", (event) => {
      if (this.unsavedChanges) {
        event.returnValue = `There are unsaved changes, are you sure you want to leave?`;
      }
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
